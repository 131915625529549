<template>
  <NuxtLink
    v-if="!isExternalLink && !isStorybook"
    :to="link"
    :rel="target === '_blank' ? 'noopener noreferrer' : null"
    :target="target"
    class="atom-link"
    :title="title"
  >
    <slot />
  </NuxtLink>
  <a
    v-else
    :href="link"
    rel="noopener noreferrer"
    :target="target"
    class="atom-link"
  >
    <slot />
  </a>
</template>

<script setup>
const props = defineProps({
    link: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    target: {
        type: String,
        default: '_self',
        validator: (value) => ['_blank', '_self'].includes(value),
    },
    title: {
        type: String,
        default: '',
    },
});

const isExternalLink = computed(
    () => props.link.includes('http://')
    || props.link.includes('https://')
    || props.link.includes('mailto:')
    || props.link.includes('tel:'),
);

/*
  Internationalization
*/
/*
  This is a workaround for Storybook until this issue is closed:
  https://github.com/storybook-vue/nuxt/issues/7
*/
const isStorybook = process.env.IS_STORYBOOK;
</script>
